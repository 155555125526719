<template>
  <div class="page-error">
    <div class="u-mt116 page-error__inner">
      <h2 class="page-error__title">{{title}}</h2>
      <p class="page-error__body" v-html="bodyText"></p>
      <image-component source="error/img-404.png" class="page-error__img"/>
      <router-link to="/" tag="div" class="c-button primary block center u-mt40">{{ $t('ホームへ') }}</router-link>
    </div>
  </div>
</template>

<script>
  import {dispatchInitialized} from "@/libs/cypress";

  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      bodyText: {
        type: String,
        required: true,
      }
    },
    mounted() {
      dispatchInitialized();
    }

  }
</script>

<style lang="scss" scoped>
  .page-error {
    &__inner {
      width: 425px;
      text-align: center;
      margin-right: auto;
      margin-left: auto;
      padding-bottom: 180px;
    }
    &__title {
      font-size: 30px;
      font-weight: $FONT_WEIGHT_BOLD;
      letter-spacing: 1px;
      white-space: nowrap;
      line-height: 1.4;
    }
    &__body {
      margin-top: 26px;
      text-align: left;
      color: $COLOR_GRAY_SECONDARY;
      line-height: 1.7;
      letter-spacing: 1px;
    }
    &__img {
      margin-top: 30px;
      width: 93px;
    }
    ::v-deep .c-button {
      padding: 18px 49px;
    }
  }
</style>
